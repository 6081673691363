<template>
    <div>
        <card-header title="Edit Question" icon="fa-pencil"/>

        <card-body>
            <question-form
                :card="card"
                :question="question"
            />
        </card-body>

        <card-footer class="pt-3 pb-3">
            <div class="columns">
                <div class="column">
                    <b-button type="is-warning is-outlined" size="is-small" @click="deleteQuestion"><i class="far fa-trash-alt"></i><span class="ml-2" v-if="confirmDelete">Delete Question?</span></b-button>
                </div>
                <div class="column has-text-right">
                    <b-button type="is-primary" size="is-small" @click="submit"><i class="fas fa-check mr-2"></i>Save Question</b-button>
                </div>
            </div>
        </card-footer>
    </div>
</template>

<script>
    import CardHeader from "../../TIER/components/CardHeader";
    import CardBody from "../../TIER/components/CardBody";
    import CardFooter from "../../TIER/components/CardFooter";
    import EventForm from "./EventForm";
    import CardList from "../../TIER/components/CardList";
    import SubheaderListItem from "../../TIER/components/SubheaderListItem";
    import ContainerListItem from "../../TIER/components/ContainerListItem";
    import async from "async";
    import {client as http} from "../../http_client";
    import QuestionForm from "@/cards/trips/QuestionForm";

    export default {
        components: {QuestionForm, ContainerListItem, SubheaderListItem, CardList, EventForm, CardFooter, CardBody, CardHeader},
        props: ['card', 'props'],
        data: function () {
            return {
                question: {},
                confirmDelete: false,
                dirty: null
            };
        },
        methods: {
            loadQuestion: function () {
                this.$emit('loading', true);
                http.get('/api/questions/' + this.props.questionId).then(response => {
                    this.question = response.data;
                    this.$emit('loading', false);
                });
            },
            submit() {
                this.$emit('loading', true);
                http.put('/api/questions/' + this.props.questionId, this.question, {force: true}).then(response => {
                    console.log(this.props);
                    this.$reloadCard('tasks');
                    this.$reloadCard('question', {questionId: this.props.questionId});
                    this.$reloadCard('questions', {tripId: this.question.trip_id});
                    this.$saveAlert(false);
                    this.$closeCard(this.card);
                }).catch(err => {

                });
            },
            deleteQuestion() {
                if (this.confirmDelete) {
                    this.$emit('loading', true);

                    http.delete('/api/questions/' + this.props.questionId).then(response => {
                        this.$reloadCard('questions', {tripId: this.question.trip_id});
                        this.$reloadCard('trip', {tripId: this.question.trip_id});
                        this.$saveAlert(false);
                        this.$closeCard(this.card);
                    }).catch(err => {

                    });
                } else {
                    this.confirmDelete = true;
                    setTimeout(() => {
                        this.confirmDelete = false;
                    }, 3000);
                }
            }
        },
        watch: {
            'props.questionId': function () {
                this.dirty = null;
                this.loadQuestion();
            },
            'question': {
                deep: true,
                handler: function () {
                    this.dirty = (this.dirty !== null);
                    if (!this.$TIER.saveAlert.active && this.dirty)
                        this.$saveAlert(true, 'Are you done editing this question? Unsaved changes will be lost.');
                }
            }
        },
        mounted() {
            this.loadQuestion();
        }
    };
</script>

<style scoped>

</style>
