<template>
    <card-list>
        <subheader-list-item icon="fa-align-left" title="Question information"/>
        <container-list-item padded>
            <div class="columns">
                <div class="column">
                    <b-field label="Question" label-position="inside">
                        <b-input v-model="question.question"></b-input>
                    </b-field>
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <b-field label="Sub-text" label-position="inside">
                        <b-input type="textarea" v-model="question.subtext"/>
                    </b-field>
                </div>
            </div>
            <div class="columns mb-3">
                <div class="column">
                    <b-field label="Type" label-position="inside">
                        <b-select expanded v-model="question.type">
                            <option value="single" selected="">Single Answer (select box)</option>
                            <option value="multi">Multiple Answer (checkmarks)</option>
                            <option value="text">Free Form Text</option>
                        </b-select>
                    </b-field>
                </div>
            </div>
        </container-list-item>
    </card-list>
</template>

<script>
    import CardList from "../../TIER/components/CardList";
    import SubheaderListItem from "../../TIER/components/SubheaderListItem";
    import ContainerListItem from "../../TIER/components/ContainerListItem";

    export default {
        name: 'QuestionForm',
        props: {
            question: {
                type: Object,
                required: true
            }
        },
        components: {ContainerListItem, SubheaderListItem, CardList}
    };
</script>
